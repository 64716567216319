/*
 * Manages link tracking for GA Events plugin
 */

// Moved here to temporarily solve bug. May need to be somewhere else eventually
var nofollow_selectors = [];

var linkTracker = ( function ( $ ) {

	var removeTrailingSlash = function ( url ) {
		//check and remove '/' at the end
		var l = url.length - 1;
		if ( url.lastIndexOf( '/' ) === l ) {
			url = url.substring( 0, l );
		}
		return url;
	};

	var noFollowLinks = function() {
			var links = [];

			if ( nofollowelem !== '' ) {
				var nofollow_arr = nofollowelem.split( ',' );
				var current = '';
				var elem;

				for ( var i = 0; i < nofollow_arr.length; i++ ) {
					current = nofollow_arr[i];
					if (0 === current.indexOf("http")) {

						links.push(removeTrailingSlash(current));

					} else if (0 === current.indexOf("id:")) {
						elem = current.substring(current.indexOf(":") + 1, current.length);
						var target = $('#' + elem);
						nofollow_selectors.push('#' + elem);
						if (target.is("a") && typeof target.href !== "undefined") {
							links.push(removeTrailingSlash(target.attr('href')));
						}
					} else if (0 === current.indexOf("class:")) {
						elem = current.substring(current.indexOf(":") + 1, current.length);
						var target = $('.' + elem);
						nofollow_selectors.push('.' + elem);
						if (target.is("a") && typeof target.href !== "undefined") {
							links.push(removeTrailingSlash(target.attr('href')));
						}
					} else if (0 === current.indexOf("advanced:")) {
						elem = current.substring(current.indexOf(":") + 1, current.length);
						var target = $(elem);
						nofollow_selectors.push(elem);
						if (target.is("a") && typeof target.href !== "undefined") {
							links.push(removeTrailingSlash(target.attr('href')));
						}
					}
				}

			}

			return links;
		}
		;

	var nofollowelem = gaeMapper.noFollowElements || '';
	var nofollow_links = [];

	$(document).ready(function () {
		nofollow_links = noFollowLinks();
	});

	/*
	 * Helper for string replacement
	 */

	var isLinkExternal = function ( url ) {
		var query = new RegExp( "//" + location.host + "($|/)" );
		if ( url.substring( 0, 4 ) === "http" ) {
			if ( !query.test( url ) ) {
				return true;
			}
		}
		return false;
	};

	var link_track_external = function ( event ) {

		var url = getUrl( event );
		if ( isLinkExternal( url ) ) {
			link_track_all( event );
		}
	};

	var link_track_external_new_tab = function ( event ) {
		var url = getUrl( event );
		if ( isLinkExternal( url ) ) {
			gaEventsMain.track_event( gaeMapper.pageTitle, 'Link', url, false );
		}
	};

	var link_track_all = function ( event ) {

		var url = getUrl( event );

		gaEventsMain.track_event( gaeMapper.pageTitle, 'Link', url, false );
		if (typeof url !== "undefined" && url !== "") {
			if ( !isNoFollowLink(url ) ||
				isNoFollowSel( '.' + event.currentTarget.className ) ||
				isNoFollowSel( '#' + event.currentTarget.id ) )  {

				event.preventDefault();
				var hash = isJustHashLink( event );
				if ( typeof hash !== "undefined" && hash !== "" ) {
					window.location.hash = hash;
				} else {
					setTimeout( function () {
						window.location = url;
					}, parseInt( gaeMapper.link_clicks_delay ) );
				}
			}
		}
	};

	var link_track_all_new_tab = function ( event ) {
		var url = getUrl( event );
		gaEventsMain.track_event( gaeMapper.pageTitle, 'Link', url, false );
	};

	var getUrl = function ( event ) {
		var url = "";
		if ( event.target.tagName !== "A" ) {
			url = $( event.target ).parents( "a" ).attr( "href" );
		} else {
			url = event.target.href;
		}

		return url;
	};


	var isNoFollowLink = function ( url ) {

		// check if advanced mode is enabled, return false if not
		if ( gaeMapper.advancedMode == 0 ) {
			return false;
		}

		//check and remove '/' at the end
		url = removeTrailingSlash( url );


		if ( nofollow_links.length > 0 && nofollow_links.indexOf( url ) > -1 ) {
			return true;
		}
		return false;
	};

	var isNoFollowSel = function ( selector ) {
		var selectors = [];
		if (selector.indexOf(".") > -1 ) {
			selectors = selector.split(" ");
			for (var i=0; i < selectors.length; i++ ) {
				if (selectors[i].indexOf(".") < 0) {
					selectors[i] = "."+selectors[i];
				}
			}
		} else {
			selectors[0] = selector;
		}
		// check if advanced mode is enabled, return false if not
		if ( gaeMapper.advancedMode == 0 ) {
			return false;
		}
		for (var j=0; j < selectors.length; j++ ) {
			if (nofollow_selectors.length > 0 && nofollow_selectors.indexOf(selectors[j]) > -1) {
				return true;
			}
		}
		return false;
	};


	var isElementInViewport = function ( elem ) {
		var docViewTop = jQuery( window ).scrollTop();
		var docViewBottom = docViewTop + jQuery( window ).height();

		var elemTop = jQuery( elem ).offset().top;
		var elemBottom = elemTop + jQuery( elem ).height();

		return ( ( elemBottom <= docViewBottom ) && ( elemTop >= docViewTop ) );
	};

	var isInt = function ( value ) {
		return !isNaN( value ) &&
			parseInt( Number( value ) ) == value &&
			!isNaN( parseInt( value, 10 ) );
	};

	var unescapeChars = function ( text ) {
		var map = {
			'&amp;': '&',
			'&lt;': '<',
			'&gt;': '>',
			'&quot;': '"',
			'\"': '"',
			'&#039;': "'"
		};
		return text.replace( /&lt;|&gt;|&quot;|'&#039;/gi, function ( m ) {
			return map[m];
		} );
	};


	var isJustHashLink = function ( event ) {
		var url = "";
		if ( event.target.tagName !== "A" ) {
			url = $( event.target ).parents( "a" ).attr( "href" );
		} else {
			url = $( event.target ).attr( "href" );
		}
		if ( typeof url !== "undefined" && url.indexOf( "#" ) === 0 ) {
			return url;
		}
		return "";
	};
	// parameter: time (time in seconds)
	var printTime = function ( time ) {
		// Hours, minutes and seconds
		var hrs = ~~( time / 3600 );
		var mins = ~~( ( time % 3600 ) / 60 );
		var secs = time % 60;

		// Output like "1:01" or "4:03:59" or "123:03:59"
		var ret = "";

		if ( hrs > 0 ) {
			ret += "" + hrs + ":" + ( mins < 10 ? "0" : "" );
		}

		ret += "" + mins + ":" + ( secs < 10 ? "0" : "" );
		ret += "" + secs;
		return ret;
	};



	// Expose link tracking methods to global scope (for binding)
	return {
		link_track_all: link_track_all,
		link_track_all_new_tab: link_track_all_new_tab,
		link_track_external: link_track_external,
		link_track_external_new_tab: link_track_external_new_tab,
		isNoFollowLink: isNoFollowLink,
		isNoFollowSel: isNoFollowSel,
		isJustHashLink: isJustHashLink,
		nofollow_links: noFollowLinks,
	};

} )( jQuery );

