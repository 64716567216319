/*
 * Script which maps DB values provided by wp_localize_script
 * to values used in v2 scripts.
 * Eventually the values provided by wp_localize script will be used directly
 * 
 * Global: ga_options, TO DO
 */

var gaeMapper = (function () {

	// Make sure options are present globally
	if (typeof ga_options !== "undefined") {

		/*
		 * We temporarily need to be able to create a selector of type id or class
		 * from the existing DB options.
		 *
		 * Eventually we will probably update the backend to store the selector as an
		 * option within the associative "click" (or otherwise named) array
		 *
		 * @param {sequential array}
		 */
		function makeSelector(click_option) {
			var selector = "";
			if (click_option.type === 'class') {
				selector += '.';
			} else if (click_option.type === 'id') {
				selector += '#';
			}

			selector += click_option.name;
			return selector;
		}

		// Click elements from DB section begins
		// TO DO clean up this code a bit to make it more like the scroll elements
		// from DB section
		var clickElementsFromDB = [];

		var click_elements_from_options = ga_options.click_elements;
		for (var i = 0; i < click_elements_from_options.length; i++) {

			var clicked = click_elements_from_options[i];
			newClickElement = {};


			newClickElement.data = {
				"select": makeSelector(clicked),
				"category": clicked.category,
				"action": clicked.action,
				"label": clicked.label,
				"bounce": parseInt(clicked.non_interaction),
				"evalue": clicked.value, // "value" in DB. Change in project?
				"universal": parseInt(ga_options.universal)
			};

			// NB was unescapeChars(select) (from original js file)
			newClickElement.selector = newClickElement.data.select;
			clickElementsFromDB.push(newClickElement);
		}

		// Click elements from DB section ends

		// Scrollelements from DB section begins

		var scroll_elements_from_options = ga_options.scroll_elements;
		var scrollElementsFromDB = [];

		// NB these will be assigned meaningful names in backend eventually
		if (typeof scroll_elements_from_options !== "undefined")
		scroll_elements_from_options.forEach(function (el) {
			scrollElementsFromDB.push({
				"select": makeSelector(el),
				"category": el.category,
				"action": el.action,
				"label": el.label,
				"bounce": parseInt(el.non_interaction),
				"evalue": el.value // "value" in DB. Change in project?
			});
		});

		// Scrollelements from DB section ends

		// YouTube videos from DB section begins

		var youTubeVideosFromOptions = ga_options.youtube_videos;
		var youTubeVideosFromDB = {};

		if (typeof youTubeVideosFromOptions !== "undefined"){
			youTubeVideosFromOptions.forEach(function (video) {
				youTubeVideosFromDB[video.name] = {
					"id_type": video.type,
					"trackPlay": video.play,
					"trackPause": video.pause,
					"trackEnd": video.end,
					"trackQuality": video.quality
				};

			});
		}


		// YouTube videos from DB section ends

		// Vimeo videos from DB section begins

		var vimeoVideosFromOptions = ga_options.vimeo_videos;
		var vimeoVideosFromDB = {};

		if (typeof vimeoVideosFromOptions !== "undefined") {
			vimeoVideosFromOptions.forEach(function (video) {
				vimeoVideosFromDB[video.name] = {
					"id_type": video.type,
					"trackPlay": video.play,
					"trackPause": video.pause,
					"trackEnd": video.end,
					"trackSkip": video.skip
				};

			});
		}


		// Vimeovideos from DB section ends

		// Link tracking section begins

		var linkTrackingDataFromDB = {
			"track": ga_options.link_tracking.track,
			"type": ga_options.link_tracking.type,
			"link_class": ga_options.link_tracking.class
		};

		// Link tracking section ends

		// Expose modified properties to global scope
		return {

			// From ga_options
			clickElementsFromDB: clickElementsFromDB,
			scrollElementsFromDB: scrollElementsFromDB,
			youTubeVideosFromDB: youTubeVideosFromDB,
			vimeoVideosFromDB: vimeoVideosFromDB,
			linkTrackingDataFromDB: linkTrackingDataFromDB,
			noFollowElements: ga_options.nofollow_links,
			forceSnippet: ga_options.force_snippet,
			advancedMode: ga_options.advanced,
			emailLinksTracking: ga_options.email_link_tracking,
			telLinksTracking: ga_options.tel_link_tracking,
			downloadTracking: ga_options.download_tracking,
			downloadTrackingFileTypes: ga_options.download_tracking_type,
			link_clicks_delay: ga_options.link_clicks_delay,
			youtube: ga_options.youtube_videos,
			vimeo: ga_options.vimeo_videos,
			media_video: ga_options.media_video,
			media_audio: ga_options.media_audio,
			snippet_type: ga_options.snippet_type,
			scriptDebugMode: ga_options.script_debug_mode,
			// from placeholders array
			isFrontPage: gaePlaceholders.is_front_page,
			isUserLoggedIn: gaePlaceholders.is_user_logged_in,
			currentUserName: gaePlaceholders.current_user_name,
			pageTitle: gaePlaceholders.page_title,
			postID: gaePlaceholders.post_id,
			category: gaePlaceholders.category,
			postAuthor: gaePlaceholders.post_author,
			currentUserId: gaePlaceholders.current_user_id
		}


	} // end if

})();


