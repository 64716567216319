/*
 * Main GA Events Plugin function
 *
 * TO DO: all variables with gaeMapper. prefix can be declared at top of function
 * and have prefix removed thereafter. Although for now it could be helpful to
 * keep prefix to make it obvious which values need to eventually be accessed
 * directly from localized values.
 *
 * Exposes track_event, click_event
 *
 * Global: TO DO
 */

var gaEventsMain = (function ($) {
  "use strict";

  // This is the tracking element.
  // It could already have been created by another plugin.
  var ga_element;

  $(document).ready(function () {
    // TO DO: This needs to be conditional on non-excluded roles!
    // (Another export from mapper is probably the best way to get these
    // at the current stage.)
    applyBindings();
  });

  /*
   * Apply bindings from values stored in DB
   */
  function applyBindings() {
    // Bind click events stored in DB to the DOM body
    // "clicked is the data which gets passed to the click_event function
    // "selector" is the id or class of the element clicked
    gaeMapper.clickElementsFromDB.forEach(function (el) {
      $("body").on("click", el.selector, el.data, click_event);
    });

    // Bind click events from shortcode / html attributes
    $(".clickevent").on("click", function (event) {
      event.data = {
        category: $(this).attr("data-event-cat"),
        action: $(this).attr("data-event-action"),
        label: $(this).attr("data-event-label"),
        bounce: $(this).attr("data-event-bounce"),
        evalue: $(this).attr("data-event-value"),
      };
      click_event(event);
    });

    // Bind scroll events stored in the DB to the window
    $(window).on("scroll", bindScrollEventsFromDB);

    if (gaeMapper.downloadTracking === "1") {
      gaeMapper.downloadTrackingFileTypes.forEach(function (ftype) {
        var lowercaseSelector = 'a[href$=".' + ftype.toLowerCase() + '"]';
        var uppercaseSelector = 'a[href$=".' + ftype.toUpperCase() + '"]';
        $("body").on("click", lowercaseSelector, lowercaseSelector, trackFileDownload);
        $("body").on("click", uppercaseSelector, uppercaseSelector, trackFileDownload);
      });
    }

    if (gaeMapper.emailLinksTracking === "1") {
      $("body").on("click", 'a[href^="mailto:"]', function (e) {
        e.preventDefault();
        e.data = {select: 'a[href^="mailto:"]'};
        e.data.label = this.href.split(":").pop();
        e.data.action = "Email Link";
        e.data.category = getPageName();
        click_event(e);
      });
    }

    if (gaeMapper.telLinksTracking === "1") {
      $("body").on("click", 'a[href^="tel:"]', function (e) {
        e.preventDefault();
        e.data = {select: 'a[href^="tel:"]'};
        e.data.label = this.href.split(":").pop();
        e.data.action = "Telephone Number Link";
        e.data.category = getPageName();
        click_event(e);
      });
    }

    // Bind link tracking events
    if (gaeMapper.linkTrackingDataFromDB.track === "1") {
      if (gaeMapper.linkTrackingDataFromDB.type === "all") {
        $("body").on("click", 'a:not([target~="_blank"])', linkTracker.link_track_all);
        $("body").on("click", 'a[target~="_blank"]', linkTracker.link_track_all_new_tab);
      } else if (gaeMapper.linkTrackingDataFromDB.type === "external") {
        $("body").on("click", 'a:not([target~="_blank"])', linkTracker.link_track_external);
        $("body").on("click", 'a[target~="_blank"]', linkTracker.link_track_external_new_tab);
      } else if (gaeMapper.linkTrackingDataFromDB.type === "class") {
        $("body").on("click", "." + gaeMapper.linkTrackingDataFromDB.link_class, linkTracker.link_track_all);
      }
    }
  }

  /**
   * Binds Scroll events from DB
   *
   * @returns {undefined}
   */
  function bindScrollEventsFromDB() {
    // TO DO this code can be simplified a lot. May be better to use
    // $('element').scroll()

    var ga_window = $(window).height();
    var ga_scroll_top = $(document).scrollTop();

    for (var i = 0; i < gaeMapper.scrollElementsFromDB.length; i++) {
      if (!gaeMapper.scrollElementsFromDB[i].sent) {
        // NB was unescapeChars( gaeMapper.scrollElementsFromDB[i].select)
        var $select = $(gaeMapper.scrollElementsFromDB[i].select);
        gaeMapper.scrollElementsFromDB[i].offset = $select.offset();

        if (gaeMapper.scrollElementsFromDB[i].offset && ga_scroll_top + ga_window >= gaeMapper.scrollElementsFromDB[i].offset.top + $select.height()) {
          track_event(get_placeholder($select, gaeMapper.scrollElementsFromDB[i].category), get_placeholder($select, gaeMapper.scrollElementsFromDB[i].action), get_placeholder($select, gaeMapper.scrollElementsFromDB[i].label), gaeMapper.scrollElementsFromDB[i].bounce, gaeMapper.scrollElementsFromDB[i].evalue);

          gaeMapper.scrollElementsFromDB[i].sent = true;
        }
      }
    }

    $(".scrollevent").each(function () {
      if (!$(this).hasClass("scrolled")) {
        var scrolloffset = $(this).offset();
        if (scrolloffset && ga_scroll_top + ga_window >= scrolloffset.top + $(this).height()) {
          track_event(get_placeholder(this, $(this).attr("data-event-cat")), get_placeholder(this, $(this).attr("data-event-action")), get_placeholder(this, $(this).attr("data-event-label")), $(this).attr("data-event-bounce"), $(this).attr("data-event-value"));
          $(this).addClass("scrolled");
        }
      }
    });
  } // End of bindScrollEvents

  /*
   * The main function for tracking events
   *
   * Param data types currently under review
   *
   * @param {string} category
   * @param {string} action
   * @param {string} label
   * @param {bool} bounce
   * @param {string}? evalue
   * @returns {undefined}
   */
  var track_event = function (category, action, label, bounce, evalue) {
    if (typeof ga_element === "undefined") {
      if (typeof ga !== "undefined") {
        ga_element = ga;
      } else if (typeof _gaq !== "undefined") {
        ga_element = _gaq;
      } else if (typeof __gaTracker === "function") {
        ga_element = __gaTracker;
      } else if (typeof gaplusu === "function") {
        ga_element = gaplusu;
      } else if (gaeMapper.snippet_type !== "gtm" && typeof dataLayer === "undefined") {
        return;
      }
    }

    var event_category = !category ? "uncategorized" : category;
    category = event_category;

    var event_action = !action ? "" : action;
    action = event_action;

    var event_label = !label ? "" : label;
    label = event_label;

    var event_value = !evalue ? "" : evalue;
    var event_bounce = !bounce ? false : bounce;

    switch (gaeMapper.forceSnippet) {
      case undefined || "none":
        if (useGoogleTagManagerSnippet()) {
          googleTagManagerEvent(category, action, label, event_value, event_bounce);
        } else if (useGlobalSiteTagSnippet()) {
          globalSiteTagEvent(category, action, label, event_value, event_bounce);
        } else if (useUniversalTrackingSnippet()) {
          universalTrackingEvent(category, action, label, event_value, event_bounce);
        } else if (useLegacySnippet()) {
          legacyTrackingEvent(category, action, label, event_value, event_bounce);
        }
        break;
      case "gtm":
        googleTagManagerEvent(category, action, label, event_value, event_bounce);
        break;
      case "gst":
        globalSiteTagEvent(category, action, label, event_value, event_bounce);
        break;
      case "universal":
        universalTrackingEvent(category, action, label, event_value, event_bounce);
        break;
    }
  }; // End of track_event function

  var useGoogleTagManagerSnippet = function () {
    return (gaeMapper.snippet_type === "gtm" || (typeof dataLayer !== "undefined" && typeof gtag === "undefined"));
  };

  var useGlobalSiteTagSnippet = function () {
    return gaeMapper.snippet_type === "gst" || typeof gtag !== "undefined";
  };

  var useUniversalTrackingSnippet = function () {
    return (gaeMapper.snippet_type === "universal" || typeof ga !== "undefined" || typeof __gaTracker === "function");
  };

  var useLegacySnippet = function () {
    return gaeMapper.snippet_type === "legacy" || typeof _gaq !== "undefined";
  };

  // Use the Google Tag Manager object to send an event
  var googleTagManagerEvent = function (category, action, label, value, bounce) {
    dataLayer.push({
      event: "WPGAE",
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventValue: value,
      nonInteraction: bounce,
    });
  };

  var globalSiteTagEvent = function (category, action, label, value, bounce) {
    gtag("event", action, {
      // Event parameters
      event_category: category, event_label: label, value: value, non_interaction: bounce,
    });
  };

  var universalTrackingEvent = function (category, action, label, value, bounce) {
    if (value) {
      ga_element("send", "event", category, action, label, value, {
        nonInteraction: bounce,
      });
    } else {
      ga_element("send", "event", category, action, label, {
        nonInteraction: bounce,
      });
    }
  };

  var legacyTrackingEvent = function (category, action, label, value, bounce) {
    ga_element.push(["_trackEvent", category, action, label, value, bounce]);
  };

  var trackFileDownload = function (e) {
    e.preventDefault();
    var selector = e.data;
    var data = {select: selector};
    data.label = this.href.split("/").pop();
    data.action = "Download";
    data.category = "";
    if ("1" === gaeMapper.isFrontPage) {
      data.category = "Home";
    } else {
      if (typeof gaeMapper.pageTitle !== "undefined") {
        data.category = gaeMapper.pageTitle;
      }
    }
    e.data = data;
    track_event(get_placeholder(this, data.category), get_placeholder(this, data.action), get_placeholder(this, data.label), false, "");
    handleLink(e);
  };

  /**
   * Click event function
   *
   * @param {event} event
   * @returns {undefined}
   */
  var click_event = function (event) {
    track_event(get_placeholder(this, event.data.category), get_placeholder(this, event.data.action), get_placeholder(this, event.data.label), event.data.bounce, event.data.evalue, this);

    if (typeof gaeMapper.link_clicks_delay !== "undefined" && gaeMapper.link_clicks_delay > 0 && typeof event.target.href !== "undefined" && event.target.nodeName == "A") {
      handleLink(event);
    }
  }; // End of click event function

  var handleLink = function (event) {
    var realTarget = "";
    if (event.target.nodeName === "A") {
      realTarget = event.target;
    } else if (typeof event.currentTarget !== "undefined" && event.currentTarget.nodeName === "A") {
      realTarget = event.currentTarget;
    }

    if (!(linkTracker.isNoFollowLink(realTarget.href) || linkTracker.isNoFollowSel(event.data.select))) {
      event.preventDefault();
      var openInNewTab = false;
      if (realTarget.target) {
        if (realTarget.target.trim() === "_blank") {
          openInNewTab = true;
        }
      }
      var w;
      if (openInNewTab) {
        w = window.open("", "_blank");
      }

      var hash = linkTracker.isJustHashLink(event);
      if (typeof hash !== "undefined" && hash !== "") {
        window.location.hash = hash;
      } else {
        setTimeout(function () {
          if (openInNewTab) {
            w.location.href = realTarget.href;
          } else {
            window.location = realTarget.href;
          }
        }, parseInt(gaeMapper.link_clicks_delay));
      }
    }
  };
  /**
   * Helper to replace placeholder with actual values
   *
   * @param string self
   * @param string placeholder
   * @returns {.f.url.value@call;replace@call;replace@call;replace@call;replace.href|String.href|DOMString|.event.target.href.href}
   */
  var get_placeholder = function (self, placeholder) {
    if (typeof placeholder === "undefined") {
      return "";
    }
    var el = placeholder;

    var lel = "";
    if (el.indexOf("$$PAGENAME$$") > -1) {
      if (true === gaeMapper.isFrontPage) {
        el = replace_with(el, "$$PAGENAME$$", "Home page");
      } else {
        el = replace_with(el, "$$PAGENAME$$", gaeMapper.pageTitle);
      }
    }

    if (el.indexOf("$$POST_ID$$") > -1) {
      el = replace_with(el, "$$POST_ID$$", gaeMapper.postID);
    }

    if (el.indexOf("$$CATEGORY$$") > -1) {
      el = replace_with(el, "$$CATEGORY$$", gaeMapper.category);
    }

    if (el.indexOf("$$ATTR_") > -1) {
      var attr = "";
      var regex = /\$\$ATTR_(.*)\$\$/g;
      var match = regex.exec(el);
      if (typeof match[1] !== "undefined") {
        attr = match[1].toLowerCase();
      }
      lel = $(this).attr(attr);
      if (typeof lel === "undefined") {
        lel = $(self).attr(attr);
      }
      console.log("el: " + el + " match: "+ match[0] + " lel: "+ lel);
      el = replace_with(el, match[0], lel);
      console.log(el);
    }

    if (el.indexOf("$$ELEMENT_TEXT$$") > -1) {
      el = replace_with(el, "$$ELEMENT_TEXT$$", $(self).text());
    }

    if (el.indexOf("$$AUTHOR$$") > -1) {
      el = replace_with(el, "$$AUTHOR$$", gaeMapper.postAuthor);
    }

    if (el.indexOf("$$REFERRER$$") > -1) {
      var referrer = document.referrer;
      if (referrer === "") {
        referrer = window.location.href;
      }
      el = replace_with(el, "$$REFERRER$$", referrer);
    }

    if (el.indexOf("$$USER$$") > -1) {
      if (0 === gaeMapper.currentUserName) {
        lel = "Guest";
      } else {
        lel = gaeMapper.currentUserName;
      }
      el = replace_with(el, "$$USER$$", lel);
    }

    if (el.indexOf("$$PAGE_URL$$") > -1) {
      el = replace_with(el, "$$PAGE_URL$$", window.location.href);
    }

    if (el.indexOf("$$IS_LOGGED_IN$$") > -1) {
      if (typeof gaeMapper.isUserLoggedIn !== "undefined" && gaeMapper.isUserLoggedIn === "1") {
        el = "true";
      } else {
        el = "false";
      }
    }

    if (el.indexOf("$$USER_ID$$") > -1) {
      if (typeof gaeMapper.currentUserId !== "undefined") {
        el = gaeMapper.currentUserId;
      } else {
        el = "";
      }
    }

    return el;
  };

  var replace_with = function (el, search, updated) {
    return el.replace(search, updated);
  };

  // Make helper functions available globally
  return {
    get_placeholder: get_placeholder, track_event: track_event, click_event: click_event,
  };

  function getPageName() {
    if ("1" === gaeMapper.isFrontPage) {
      return "Home";
    } else {
      if (typeof gaeMapper.pageTitle !== "undefined") {
        return gaeMapper.pageTitle;
      }
    }
    return "";
  }
})(jQuery);
